<!-- 第三方登录中间页面 -->
<template>
  <div class="empty_page">
    <div class="message" v-if="showMessage">
      <h1>{{ message }}</h1>
    </div>
  </div>
</template>
<script>
import * as api from '@/api'
import cookie from '@/configs/cookie'
export default {
  props: {
    // 第三方类型
    client: {
      type: String,
      default: ''
    },
    // 第三方登录操作类型 authorize,bind
    action: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      message: '授权成功，等待跳转',
      showMessage: false
    }
  },
  computed: {
    // 第三方带来的信息
    queryData () {
      return {
        // 第三方机构给的code
        code: this.$route.query.code || '',
        // 第三方机构给的state
        state: this.$route.query.state || ''
      }
    }
  },
  created () {
    // 如果存在code参数，并且当前操作标识不为空
    if (this.queryData.code !== '' && this.action !== '') {
      // 进行登录操作
      if (this.action === 'authorize') {
        this.thirdPartyLogin()
      } else if (this.action === 'bind') {
        this.thirdPartyBind()
      }
    // 没有code参数 没有授权或者说取消了授权
    } else {
      this.setMessage('已取消授权, 正在返回')
    }
  },
  methods: {
    // 进行登录操作
    thirdPartyLogin () {
      const loading = this.$loading()
      api.thirdPartyLogin(this.client, {
        code: this.queryData.code,
        state: this.queryData.state
      }).then(res => {
        loading.close()
        if (res.data.code === 0) {
          // 用户绑定了才会返回
          cookie.set(this.globalVar.WEB_TOKEN, res.data.data.token)
          this.$store.commit('setUserInfo', res.data.data.user)
          this.setMessage('登录成功，正在跳转')
        } else {
          // 如果此微信没有绑定相关账号的话，会有message
          this.$message.error(res.data.message)
          this.setMessage(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        loading.close()
        this.setMessage()
      })
    },

    // 把code和state返回给后端
    thirdPartyBind () {
      const loading = this.$loading()
      api.thirdPartyBind(this.client, {
        code: this.queryData.code,
        state: this.queryData.state
      }).then(res => {
        loading.close()
        if (res.data.code === 0) {
          // 进行绑定操作
          console.log('绑定操作成功', res.data.message)
          this.setMessage('绑定成功，正在返回')
        } else {
          this.$message.error(res.data.message)
          this.setMessage(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        loading.close()
        this.setMessage()
      })
    },

    // 跳转失败 或者没有code参数 没有进行授权
    setMessage (message = '操作失败, 正在返回', count = 3) {
      // 打开文字提示
      this.showMessage = true
      this.message = message

      const interval = setInterval(() => {
        if (count <= 0) {
          clearInterval(interval)
          // 如果是在进行第三方登录操作的话 去首页，未登录则自动跳转到登录页
          if (this.action === 'authorize') {
            this.$router.replace('/')
          // 如果是在进行账号的绑定的话 去个人中心
          } else if (this.action === 'bind') {
            this.$router.replace('/personal-info')
          }
        } else {
          count--
          this.message = `${this.message}...${count}`
        }
      }, 1000)

      /* setTimeout(() => {
        // 如果是在进行第三方登录操作的话 去首页，未登录则自动跳转到登录页
        if (this.action === 'authorize') {
          this.$router.replace('/')
        // 如果是在进行账号的绑定的话 去个人中心
        } else if (this.action === 'bind') {
          this.$router.replace('/personal-info')
        }
      }, timeout) */
    }
  }
}
</script>
<style lang="scss" scoped>
  .empty_page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    .message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 150px;
      border: 1px solid $color-primary;
      h1 {
        font-size: 12px;
        color: #303133;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
</style>
